import { Air } from '@air/next-icons';
import { ModalCloseButton } from '@air/primitive-modal';
import { memo } from 'react';

export const AirModalLogoHeader = memo(({ onClose }: AirModalProps) => (
  <div className="flex items-center justify-center">
    <Air className="mt-[18px] h-[80px] text-blue-9" />
    <ModalCloseButton
      data-testid="AIR_MODAL_HEADER_CLOSE_BUTTON"
      className="absolute right-8 top-8 size-8 shrink-0"
      size="small"
      onClick={onClose}
    />
  </div>
));

AirModalLogoHeader.displayName = 'AirModalLogoHeader';
