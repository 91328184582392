import { Tooltip } from '@air/primitive-tooltip';
import { Upload } from '@air/redux-uploader';
import classNames from 'classnames';

import FileExtensionPreview from '~/components/FileExtensionPreview';
import { useUploadingData } from '~/components/Upload/hooks/useUploadingData';
import { UPLOADING_FILE } from '~/constants/testIDs';
import { useHover } from '~/hooks/useHover';

export interface UploadingFileGalleryCardProps {
  upload: Upload;
  width: number;
  getClassName?: (props: { isHovering: boolean }) => string;
}

/**
 * This component is used to display uploading file with upload progress and shimmer animation
 */
export const UploadingFileGalleryCard = ({ upload, width, getClassName }: UploadingFileGalleryCardProps) => {
  const {
    apiUploadData: { ext, size },
  } = upload;
  const { stateText } = useUploadingData(upload);
  const { handleHover, handleUnhover, isHovering } = useHover();
  const title = upload.file.name;

  return (
    <div
      data-testid={UPLOADING_FILE}
      data-asset-title={title}
      onMouseEnter={handleHover}
      onMouseMove={handleHover}
      onMouseLeave={handleUnhover}
      className="relative flex h-full flex-col"
    >
      <div
        className={classNames(
          'absolute inset-[-6px] cursor-default rounded border-2 border-transparent',
          isHovering && 'bg-pigeon-50',
          getClassName?.({ isHovering }),
        )}
      />
      <div className="relative flex  grow flex-col rounded border border-pigeon-100 bg-white p-3">
        <div className="flex grow">
          <div className="flex size-8 shrink-0 items-center justify-center rounded bg-pigeon-100">
            <FileExtensionPreview ext={ext} color="gray" />
          </div>
        </div>
        <div className="relative">
          {!!size && (
            <Tooltip label={title} side="top" sideOffset={4}>
              <div className="pointer-events-auto truncate text-left font-medium text-pigeon-700 hover:underline">
                {title}
              </div>
            </Tooltip>
          )}
          <p style={{ width }} className="truncate text-12 text-pigeon-400">
            {stateText}
          </p>
        </div>
      </div>
    </div>
  );
};
