import { Tooltip } from '@air/primitive-tooltip';
import { formatBytes } from '@air/utilities';
import classNames from 'classnames';
import { toUpper } from 'lodash';
import pluralize from 'pluralize';
import { memo } from 'react';

import { isPdfRenderedExtension } from '~/utils/ClipUtils';

export interface AssetGalleryCardMetaDetailsProps {
  hasDarkBackground?: boolean;
  onTitleClick?: () => void;
  onTitleCmdClick?: () => void;
  titleTestId?: string;
  title: string;
  clip: {
    ext: string;
    size: number;
    width?: number;
    height?: number;
    pages?: number;
  };
  width: number;
}

export const AssetGalleryCardMetaDetails = memo(
  ({
    hasDarkBackground,
    clip,
    title,
    onTitleClick,
    onTitleCmdClick,
    width,
    titleTestId,
  }: AssetGalleryCardMetaDetailsProps) => {
    return (
      <div>
        {/**
         * This width on the div is necessary to make the truncate work on the button
         * We also don't want the button full width because we want to be able to select the card
         */}
        <div className="pointer-events-none flex" style={{ width }}>
          <Tooltip label={title} side="top" sideOffset={4}>
            <button
              data-testid={titleTestId}
              disabled={!onTitleClick}
              onClick={(event) => {
                if ((event.metaKey || event.ctrlKey) && !!onTitleCmdClick) {
                  onTitleCmdClick?.();
                } else {
                  onTitleClick?.();
                }
              }}
              className={classNames(
                'pointer-events-auto truncate text-left font-medium hover:underline',
                hasDarkBackground ? 'text-white' : 'text-grey-12',
                !onTitleClick && 'cursor-default',
              )}
            >
              {title}
            </button>
          </Tooltip>
        </div>
        <p
          style={{ width }}
          className={classNames('truncate text-12', hasDarkBackground ? 'text-white' : 'text-grey-11')}
        >
          <>
            {`${toUpper(clip.ext)} · ${formatBytes(clip.size)}`}
            {!!clip.pages && <p className="inline-flex items-center">&nbsp;· {pluralize('page', clip.pages, true)}</p>}
            {/* audio clips get displayed as visual assets but have null width and height */}
            {!isPdfRenderedExtension(clip.ext) && !!clip.width && !!clip.height && ` · ${clip.width} x ${clip.height}`}
          </>
        </p>
      </div>
    );
  },
);

AssetGalleryCardMetaDetails.displayName = 'AssetGalleryCardMetaDetails';
